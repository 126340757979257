import { getPersonalInfo } from '_services/personalInfoService';

const handleCommunityEngagementPageLoadEvent = (dataLayer: any, fullUrl: string, pageTitle: string, pageUrl: string) => {

  const url = new URL(fullUrl);
  const queryParams = new URLSearchParams(url.search);

  const surveyId = queryParams.get('survey');
  const socialpinpointId = queryParams.get('socialpinpoint');
  const nextPage = queryParams.get('next-page');

  let feedback_type;

  if (surveyId) {
    feedback_type = 'survey';
  } else if (socialpinpointId) {
    feedback_type = 'map_pin';
  } else if (nextPage) {
    feedback_type = 'community_ideas';
  }

  getPersonalInfo(
    (res: any) => {
      const userId = res?.data?.personalInfo?.individualId || '';
      dataLayer.push({
        event: 'select_profile',
        page_title: pageTitle,
        page_url: pageUrl,
        feedback_type,
        userId
      });
    },
    (error: any) => {
      dataLayer.push({
        event: 'select_profile',
        page_title: pageTitle,
        page_url: pageUrl,
        feedback_type
      });
    }
  );
};

const handleCommunityEngagementWidgetEvent = (dataLayer: any, eventData: any, pageTitle: string, pageUrl: string) => {

  dataLayer.push({
    ...eventData,
    page_title: pageTitle,
    page_url: pageUrl
  });
  
};

const handleCommunityEngagementLink = (dataLayer: any, linkUrl: string, pageTitle: string, pageUrl: string) => {

  const isSurveryLink = linkUrl.includes('account%2Fyour-profile%2Fcommunity-engagement%3Fsurvey');
  const isSocialPinpointLink = linkUrl.includes('account%2Fyour-profile%2Fcommunity-engagement%3Fsocialpinpoint');

  let feedback_type;

  if (isSurveryLink) {
    feedback_type = 'survey';
  } else if (isSocialPinpointLink) {
    feedback_type = 'map_pin';
  }

  dataLayer.push({
    event: 'form_start',
    page_title: pageTitle,
    page_url: pageUrl,
    feedback_type
  });

};

export const handlePageLoadEvent = (dataLayer: any, fullUrl: string, pageTitle: string, pageUrl: string) => {
  
  if (pageUrl.includes('account/your-profile/community-engagement')) {
    handleCommunityEngagementPageLoadEvent(dataLayer, fullUrl, pageTitle, pageUrl);
  }
  
};

export const handleWidgetEvent = (dataLayer: any, eventData: any, pageTitle: string, pageUrl: string) => {

  if (eventData.event === 'community_ideas_engagement') {
    handleCommunityEngagementWidgetEvent(dataLayer, eventData, pageTitle, pageUrl);
  }

};

export const handleLinkClickEvent = (dataLayer: object, linkUrl: string, pageTitle: string, pageUrl: string) => {

  const isCommunityEngagementLink = linkUrl.includes('account%2Fyour-profile%2Fcommunity-engagement');

  if (isCommunityEngagementLink) {
    handleCommunityEngagementLink(dataLayer, linkUrl, pageTitle, pageUrl);
  }

};